export default [
    {
        path: '/fenaju', component: () => import('./components/include/Page.vue'), name: 'fenaju.page', children: [
            {path: '', component: () => import('./components/Dashboard.vue'), name: 'fenaju.suporte'},
            {
                path: 'tarefas',
                component: () => import('./components/Tarefas.vue'),
                name: 'fenaju.tarefas',
                children: [
                    {
                        path: ':id',
                        component: () => import('./components/suporte/tarefas/Tarefa.vue'),
                        name: 'fenaju.tarefa'
                    }
                ]
            },
            {
                path: 'registros',
                component: () => import('./components/Clientes.vue'),
                name: 'fenaju.registros',
                children: [
                    {
                        path: 'lista',
                        component: () => import('./components/clientes/Lista.vue'),
                        name: 'fenaju.registros.lista',
                        children: [
                            {
                                path: ':id',
                                component: () => import('./components/clientes/Cliente.vue'),
                                name: 'fenaju.registros.registro',
                                children: [
                                    {
                                        path: 'financeiro',
                                        component: () => import('./components/clientes/Financeiro.vue'),
                                        name: 'fenaju.registros.registro.financeiro'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'leiloeiros',
                component: () => import('./components/Leiloeiros.vue'),
                name: 'fenaju.leiloeiros'
            },
            {path: 'financeiro', component: () => import('./components/Financeiro.vue'), name: 'fenaju.financeiro'},
            {
                path: 'tickets',
                component: () => import('./components/Clientes.vue'),
                name: 'fenaju.tickets.main',
                children: [
                    {
                        path: '',
                        component: () => import('./components/tickets/Lista.vue'),
                        name: 'fenaju.tickets',
                    }
                ]
            },
            {
                path: 'tradutores',
                component: () => import('./components/Tradutores.vue'),
                name: 'fenaju.tradutores',
                children: [
                    {
                        path: '',
                        component: () => import('./components/tradutores/Lista.vue'),
                        name: 'fenaju.tradutores.lista',
                        children: [
                            {
                                path: ':id',
                                component: () => import('./components/tradutores/Tradutor.vue'),
                                name: 'fenaju.tradutores.registro'
                            }
                        ]
                    }
                ]
            },
        ]
    }
]
